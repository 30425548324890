import httpClient from "./httpClient";

export default {
  async login(body) {
    const res = await httpClient({ requiresAuth: false }).post("/login", body);

    return res.data.token;
  },
  async getProfile() {
    const res = await httpClient({ requiresAuth: true }).get("/profile");

    return res.data;
  },
  async deleteFile(id) {
    const res = await httpClient({ requiresAuth: true }).delete(`/file/${id}`);

    return res.data;
  },
  async getCommons(model) {
    const res = await httpClient({ requiresAuth: true }).get(
      `/commons/${model}`
    );

    return res.data;
  },
  async checkIMEI(imei) {
    const res = await httpClient({ requiresAuth: true }).get(
      `/check-imei/${imei}`
    );

    return res;
  },
  async getSystemNo() {
    const res = await httpClient({ requiresAuth: true }).get("/system-no");

    return res.data;
  },
  async lastestUpdates(page) {
    const res = await httpClient({ requiresAuth: true }).get(
      "/lastest-updates?page=" + page
    );

    return res.data;
  },

  async getAllBrands() {
    const response = await httpClient({ requiresAuth: true }).get("/brands");
    return response.data;
  },

  async getBrand(id) {
    const response = await httpClient({ requiresAuth: true }).get(
      `/brands/${id}`
    );
    return response.data;
  },

  async addBrand(brand) {
    const response = await httpClient({ requiresAuth: true }).post(
      "/brands",
      brand
    );
    return response.data;
  },

  async updateBrand(id, brand) {
    const response = await httpClient({ requiresAuth: true }).put(
      `/brands/${id}`,
      brand
    );
    return response.data;
  },

  async deleteBrand(id) {
    const response = await httpClient({ requiresAuth: true }).delete(
      `/brands/${id}`
    );
    return response.data;
  },

  async getAllVehicleModels() {
    const response = await httpClient({ requiresAuth: true }).get(
      "/vehicle-models"
    );
    return response.data;
  },

  async getVehicleModel(id) {
    const response = await httpClient({ requiresAuth: true }).get(
      `/vehicle-models/${id}`
    );
    return response.data;
  },

  async addVehicleModel(vehicleModel) {
    const response = await httpClient({ requiresAuth: true }).post(
      "/vehicle-models",
      vehicleModel
    );
    return response.data;
  },

  async updateVehicleModel(id, vehicleModel) {
    const response = await httpClient({ requiresAuth: true }).put(
      `/vehicle-models/${id}`,
      vehicleModel
    );
    return response.data;
  },

  async deleteVehicleModel(id) {
    const response = await httpClient({ requiresAuth: true }).delete(
      `/vehicle-models/${id}`
    );
    return response.data;
  },

  async addCommon(model, data) {
    const response = await httpClient({ requiresAuth: true }).post(
      `/commons/${model}`,
      data
    );

    return response.data;
  },
};
