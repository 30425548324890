import store from '@/state/store';

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn'] || localStorage.getItem('user')) {
          // Redirect to the home page instead
          next({
            name: 'home',
          });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../views/pages/account/register'),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters['auth/loggedIn']) {
  //         // Redirect to the home page instead
  //         next({
  //           name: 'home'
  //         })
  //       } else {
  //         // Continue to the login page
  //         next()
  //       }
  //     },
  //   },
  // },
  // {
  //   path: '/forgot-password',
  //   name: 'Forgot-password',
  //   component: () => import('../views/pages/account/forgot-password'),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters['auth/loggedIn']) {
  //         // Redirect to the home page instead
  //         next({
  //           name: 'home'
  //         })
  //       } else {
  //         // Continue to the login page
  //         next()
  //       }
  //     },
  //   },
  // },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch('auth/logOut')
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          store.dispatch('authfack/logout')
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? {
          name: 'home'
        } : {
          ...routeFrom
        })
      },
    },
  },
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (routeTo === routeFrom) {
          // Navigate back to previous page, or home as a fallback
          next()
        } else {
          // Navigate to requested page
          next()
        }
      },
    },
    component: () => import('../views/pages/dashboard/index')
  }, {
    path: '/customer',
    name: 'customer',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/customer/index')
  }, {
    path: '/customer/id',
    name: 'customer-id',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/customer/add')
  }, {
    path: '/fleet',
    name: 'fleetTracking',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/fleetTracking/index')
  }, {
    path: '/report',
    name: 'report',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/report/index')
  }, {
    path: '/setting',
    name: 'setting',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/setting/index')
  }, {
    path: '/users',
    name: 'users',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/users/index')
  }, {
    path: '/users/add',
    name: 'users-add',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/users/add')
  }, {
    path: '/setting/add-car-brand',
    name: 'add-car-brand',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/setting/add-car-brand')
  }
]
